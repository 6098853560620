.ReviewablePreview {
  display: grid;
  grid-template-columns: 200px 3fr;
  grid-gap: 1rem;
}

.ReviewablePreview {
  margin: 2rem 0;
}

.ReviewablePreview img {
  width: 100%;
  border: 1px white solid;
}

.ReviewablePreview .date {
  font-family: monospace;
  font-size: 1rem;
  color: grey;
}

.ReviewablePreview .buttonRow {
  display: flex;
  flex-wrap: wrap;
}

.ReviewablePreview .buttonRow > * {
  margin-top: 1rem;
}

.ReviewablePreview .buttonRow > *:not(:last-child) {
  margin-right: 1rem;
}

.ReviewablePreview .summaryOfReviews {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .ReviewablePreview {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
}

@media screen and (min-width: 800px) {
  .ReviewablePreview h2 {
    margin-top: 0;
  }
}