.ReviewForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}

.ReviewForm label {
  margin-bottom: 1rem;
}

.ReviewForm .labelAndCounterContainer:not(:first-child) {
  margin-top: 2rem;
}

.ReviewForm .Button, .ReviewForm .LoadingSpinner {
  margin-top: 2rem;
}

.ReviewForm input, .ReviewForm textarea {
  background-color: var(--darker-background-colour);
  color: var(--brighter-text-colour);
  border: 1px solid white;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 1rem;
}

.ReviewForm input:disabled, .ReviewForm textarea:disabled {
  color: grey;
}

.ReviewForm input {
  padding: 0.5rem;
}

.ReviewForm textarea {
  resize: vertical;
  padding: 1rem;
  height: 15rem;
}

.ReviewForm .labelAndCounterContainer {
  display: flex;
  justify-content: space-between;
}

.ReviewForm .errorMessage {
  color: orange;
  margin: 0;
  margin-top: 2rem;
  text-align: center
}