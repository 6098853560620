.Stars {
  display: flex;
}

.Stars svg {
  fill: grey;
}

.Stars div[data-checked="true"] svg {
  fill: gold;
}

.Stars div[data-editable="true"] svg:hover {
  fill: white;
}

.Stars > div:nth-child(2n) svg {
  transform: scale(-1, 1);
}

.Stars > div:nth-child(2n) svg {
  margin-right: 1rem;
}